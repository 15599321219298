/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import { Fancybox } from '@fancyapps/ui'
import './assets/js/css/ImportCss'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import HeaderMobile from '@/assets/js/Header/HeaderMobile/HeaderMobile'
import BlockScroll from '@/assets/js/BlockScroll/BlockScroll'
import form from '@/store/form'
import AppFormPopupProduct from '@/components/Form/Popup/FormPopupProduct/AppFormPopupProduct.vue'

const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-subscribe]')
    new SwiperSlider()
    new HeaderMobile()
    new BlockScroll()

    if(document.getElementById('block-form--product')!= null) {
        createApp({})
            .component('form-popup-product', AppFormPopupProduct)
            .use(Maska)
            .use(form)
            .mount('#block-form--product')
    }
})
