/*global $*/
/*eslint no-undef: "error"*/

export default class BlockScroll {
    classButton = 'block--button'
    classHeader = 'header'
    classHeaderBlock = 'header--block'
    classHeaderBlockOpen = 'header-mobile-open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function (e) {
            let block = $(this).data('block')
            let heightHeader = $(`.${self.classHeader}`).height()
            console.log(heightHeader)

            $('html,body').stop().animate(
                {
                    scrollTop: $(`#${block}`).offset().top - heightHeader
                },
                500
            )

            $(`.${self.classHeaderBlock}`).removeClass(self.classHeaderBlockOpen)
            e.preventDefault()
            return false
        })
    }
}
